import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import parse from "html-react-parser"
import Placeholderimage from "./placeholderimage"
// import { GatsbyImage } from "gatsby-plugin-image"

// import { graphql, useStaticQuery } from 'gatsby'



const Latest = () => {
  const query = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: [date], order: DESC}, limit: 1) {
      edges {
        node {
          id
          title
          featuredImageId
          link
          uri
          date(formatString: "DD MMMM, YYYY")
          dateGmt
          author{
            node {
              id
              name
              nicename
              firstName
              lastName
            }
          }
          categories{
            nodes{
              name
            }
          }
          featuredImage {
            node {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`)




  const arr = query.allWpPost.edges[0].node;
  const img_src = arr?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  const img_srcset = arr?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.srcSet;
  const img_sizes = arr?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.sources?.sizes;

  return (
    <div>
      {/* <pre>{JSON.stringify(arr, null, 4)}</pre> */}
      <Link to={arr.link} className="flex flex-col mx-1 items-center bg-white rounded-lg border shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:text-white  dark:border-secondary dark:hover:bg-gray-700">
        {img_src? (
          <img srcSet={img_srcset} sizes={img_sizes} src={img_src} className="object-cover w-full h-96 rounded-lg md:h-auto md:rounded-none md:rounded-lg" alt="" />   
        ):(
          <Placeholderimage className="flex flex-col mx-1 items-center bg-white rounded-lg border shadow-md hover:bg-gray-100 dark:border-gray-700  dark:hover:bg-gray-700" />
        )}
        <div className="flex flex- self-start flex-col justify-start p-4 leading-normal">
            <h5 className="mb-2 text-2xl text-blue-650 font-semibold dark:text-slate-300">{arr.title}</h5>
            <p className="mb-3 font-normal text-blue-500 dark:text-gray-400 text-xs">
              <div className="flex opacity-75">
                <span>
                  {new Date(arr.date).getDate()}&nbsp; 
                  {new Date(arr.date).toLocaleString('default', { month: 'long' })}&nbsp; 
                  {new Date(arr.date).getFullYear()} 
                </span>
                &nbsp;
                <span className="pr-2"> {arr.categories.nodes[0].name}</span>
                {/* <span>
                  <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>
                </span>
                <span className="pr-2">100</span>

                <span>
                   <svg class="h-5 w-5 text-blue-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                </span>
                <span>200</span> */}
              </div>
              <div className="flex opacity-75">
                {arr.author.node.name}
              </div>
            </p>
        </div>
      </Link>
    </div>
  )
}


// const ComponentName = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>



// export default Latest

export default function LatestRender() {
  return (
    <>
    <div className="bg-white pb-2 dark:border-gray-700 dark:bg-gray-800  dark:text-white">
       <h3 className="block mb-4 text-1xl  font-semibold p-2 dark:border-gray-700 dark:bg-gray-800  dark:text-white">Top Story</h3>
       <Latest />
    </div>
      
    </>
  );
}

