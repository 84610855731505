import React from "react"
// import { graphql } from "gatsby"
import Latest from "./latest"

const ComponentName = ({ data }) => <pre>
  <Latest />
  {JSON.stringify(data, null, 4)}</pre>

// export const query = graphql`
//   {
//     allWpPost(sort: {fields: [date], order: DESC}, limit: 1) {
//       edges {
//         node {
//           id
//           title
//           featuredImageId
//           link
//           uri
//           featuredImage {
//             node {
//               id
//               gatsbyImage(fit: COVER, width: 10, quality: 10)
//             }
//           }
//           excerpt
//           content
//           categories {
//             nodes {
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default ComponentName
